import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

export default function LoadingComponent({ message = "Preparing the Application...", fit = false }) {

    const sizing = fit ? {
        height: '100%',
        width: '100%',
    } : {
        height: '100vh',
        width: '100vw',
    }

    return (
        <Box
            sx={{
                ...sizing,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                gap: 2
            }}
        >
            <Typography 
                variant="overline"
                sx={{
                    color: 'grey',
                    userSelect: 'none'
                }}
            >
                {message}

            </Typography>
            <CircularProgress size={30} />
        </Box>
    );
}