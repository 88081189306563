import React from "react";

function CrashScreen (props) {
    const {info, error} = props
    return (
        <>
        <div>
            Something went wrong while we tried to render the page. Please copy this text and open a ticket.
        </div>
        <pre>
            Message: {error?.message + '\n'}
            Stack: {error?.stack + '\n'}
            Info: {JSON.stringify(info)?.replaceAll('\\n', '\n')}
        </pre>
        <div>
            Click <a href={window.location.href}>here</a> to try reloading the page.
        </div>
        </>
    )
}

export default CrashScreen;