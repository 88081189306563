import React from "react";

// Error boundaries have to be a class component
export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null, info: null };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        this.setState({
            error, info, hasError: this.state.hasError
        })
        console.error(error, info.componentStack)
    }

    render() {
        if (this.state.hasError) {
            // Fallback can be jsx or a render function.
            if (typeof this.props.fallback === 'function') {
                return <this.props.fallback error={this.state.error} info={this.state.info} />;
            }

            return this.props.fallback;

        }

        return this.props.children;
    }
}