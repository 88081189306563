// Bug workaround - "createtheme_default is not a function" https://github.com/mui/material-ui/issues/31835
import { ThemeProvider } from "@mui/material";
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
// import { white } from "@mui/material/colors";
// import { deepmerge } from '@mui/utils';


import {useState, useEffect } from 'react'
import { fallbackReportError } from './utility/errornormalizer';
import LoadingComponent from './main/holdings/components/LoadingComponent';

import ErrorBoundary from "./main/holdings/components/ErrorBoundary";
import CrashScreen from "./main/holdings/components/CrashScreen";


import { Provider } from "react-redux";

import {HTML5Backend} from 'react-dnd-html5-backend'
import {DndProvider} from 'react-dnd'


// window.addEventListener("unhandledrejection", fallbackReportError, false)
// window.addEventListener("error", fallbackReportError, false)
const themeBase = {
    palette: {
        common: {
            black: '#000',
            white: '#fff',
            gray: '#ddd',
            red: '#F44336',
            green: '#01CD9B',
            darkGray: '#00000099',
            lightGray: '#00000022'
        },
        primary: {
            main: '#2B4264',
            orange: '#E6AF00',
        },
        secondary: {
            main: '#76A3CE'
        },
        chart: {
            blue1: '#04466C',
            blue2: '#1E4AB5',
            blue3: '#1C6BE4',
            blue4: '#75D8FF',
            blue5: '#76a6ef',
            green1: '#01AF86',
            green2: '#01CD9B',
            green3: '#6DED98',
            green4: '#7DFFA2',
            orange1: '#E6AF00',
            orange2: '#FFC000',
            gray1: '#BBC2C7',
            gray2: '#E9E9E9',
            gray3: '#899095',
            yellow1: '#FFD700',
        }
    },
    typography: {
        fontFamily: [
            '"Duru Sans"',
            '"Brandon Grotesque"',
            'Roboto',
            'Calibri',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif'
        ].join(','),
        fontWeight: {
            normal:500,
            bold:700
        }
    }
}

let theme = createTheme(themeBase);
// Add custom 'white' color.
// theme = createTheme(theme, {
//     palette: {
//         white: theme.palette.augmentColor({
//             color: {
//                 main: '#FFF',
//             },
//             name: 'white',
//         }),
//     }
// });  

theme = responsiveFontSizes(theme);

const App = () => {

    const [RootModule, setRootModule] = useState(null);
    const [rootStore, setRootStore] = useState(null);
    
    // Refactoring this may break tree shaking, double check before moving it.
    useEffect(() => {
        (async () => {
            let m;
            let s;
            if (import.meta.env.REACT_APP_MODE === 'BOOKS') {
                m = import('./RootBooks')
                s = import('./store')
            } else {
                m = import('./RootPortal')
                s = import('./portal/store')
            }
            const lib = await m;
            const st = await s;
            setRootModule(() => lib.default);
            setRootStore(() => st.default);
            document.title = import.meta.env.REACT_APP_MODE === 'BOOKS' ? 'Client Portfolio Books' : 'Client Portal'
        })();
    }, [])


    return (
        <ErrorBoundary fallback={CrashScreen}>
            <ThemeProvider theme={theme}>
                <DndProvider backend={HTML5Backend}>
                {!RootModule ? (
                    <LoadingComponent />
                ) :
                    (
                        <Provider store={rootStore}>
                            <RootModule />
                        </Provider>
                    )}
                </DndProvider>
            </ThemeProvider>
        </ErrorBoundary>
    )
}

export default App;
